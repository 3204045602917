// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  googleTagManagerId: '', 
  url: 'https://staging-api.imagefootage.com/api/',
  front_url: 'https://staging.imagefootage.com/',
  recaptcha: {
    siteKey: '6Lch9i4pAAAAABw22amVkk92W9ZYGhlYBtVkGl-9',
  },
  googleClientId: '943572922170-f20ed6bnjvrsh8a0uf5nv89f06cfbufo.apps.googleusercontent.com',
  fbAppId: '3252652105034321',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

